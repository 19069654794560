import React, { useMemo } from 'react'
import {
  Alert,
  Box,
  Button,
  Masthead,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineDotProps,
  TimelineItem,
  TimelineSeparator,
  Typography
} from '@barracuda-internal/bds-core-v2'
import { Checkmark } from '@barracuda-internal/bds-core/dist/Icons/Core'
import ErrorPage from 'components/libs/errorPage/ErrorPage'
import { useFormatMessage } from 'lib/localization'
import SetupFlowStepsContainer from './layout/SetupFlowStepsContainer'
import PageActionContainer from './layout/PageActionContainer'
import SetupFlowContainer from './layout/SetupFlowContainer'
import SetupFlowContentContainer from './layout/SetupFlowContentContainer'
import Introduction from './views/introduction/Introduction'
import { useSetupFlowLogic } from './useSetupFlowLogic'
import PageContainer from './layout/PageContainer'
import LoadingComponent from './components/LoadingComponent'
import { SetupFlowSteps } from './types/egdWizardTypes'
import VerifyDomain from './views/verifyDomain/VerifyDomain'
import EmailFlow from './views/emailFlow/EmailFlow'
import DeploymentSetup from './views/deploymentSetup/DeploymentSetup'
import MicrosoftConnected from './views/microsoftConnected/MicrosoftConnected'
import RegionSelection from './views/regionSelection/RegionSelection'

const BASE_I18N_KEY = 'ess_wizard'

const SetupFlow: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useSetupFlowLogic()

  return useMemo(
    () => (
      <PageContainer>
        {state.isGetEmailServerSettingsInProgress && <LoadingComponent />}
        {state.isGetEmailServerSettingsIsSuccess && (
          <Box>
            <Masthead
              productTitle={formatMessage('email_security')}
              pageTitle={formatMessage('product_setup')}
              rightContent={
                <PageActionContainer>
                  <Button onClick={eventHandlers.onExit} bdsType="interactiveNeutral">
                    {formatMessage('exit')}
                  </Button>
                  <Button onClick={eventHandlers.onNext} bdsType="interactiveEmphasis">
                    {formatMessage('next')}
                  </Button>
                </PageActionContainer>
              }
            />
            <SetupFlowContainer>
              <SetupFlowContentContainer>
                <SetupFlowStepsContainer>
                  <Timeline>
                    {state.steps.map((step, index) => {
                      let color: TimelineDotProps['color'] = 'grey'
                      if (step.completed) {
                        color = 'success'
                      } else if (step.active) {
                        color = 'primary'
                      }

                      return (
                        <TimelineItem key={step.text}>
                          <TimelineSeparator>
                            <TimelineDot variant="outlined" color={color}>
                              {step.completed && <Checkmark />}
                              {!step.completed && index + 1}
                            </TimelineDot>
                            {state.steps.length - 1 !== index && <TimelineConnector />}
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography>{formatMessage(step.text)}</Typography>
                          </TimelineContent>
                        </TimelineItem>
                      )
                    })}
                  </Timeline>
                </SetupFlowStepsContainer>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                  }}
                >
                  {state.error && (
                    <Alert bdsBoxShadowType="inline" bdsType="neutralError">
                      {state.error}
                    </Alert>
                  )}
                  {state.currentStep === SetupFlowSteps.introduction && <Introduction />}
                  {state.currentStep === SetupFlowSteps.deploymentSetup && <DeploymentSetup />}
                  {state.currentStep === SetupFlowSteps.microsoftConnected && <MicrosoftConnected />}
                  {state.currentStep === SetupFlowSteps.regionSelection && <RegionSelection />}
                  {state.currentStep === SetupFlowSteps.verifyDomain && <VerifyDomain />}
                  {state.currentStep === SetupFlowSteps.emailFlow && <EmailFlow />}
                </Box>
              </SetupFlowContentContainer>
            </SetupFlowContainer>
          </Box>
        )}
        {state.isGetEmailServerSettingsFailed && (
          <ErrorPage title={formatMessage('error_page.title')} subtitle={formatMessage('error_page.subtitle')} />
        )}
      </PageContainer>
    ),
    [state, eventHandlers, formatMessage]
  )
}

export default SetupFlow
