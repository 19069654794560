import { useEffect } from 'react'
import { v4 as makeUuid } from 'uuid'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isSuccess } from 'redux/toolkit/api'
import { createAuthenticationProcess } from 'redux/features/oauth2/oauth2Slice'
import routesConfig from 'lib/routesConfig'
import { OAuth2Connection } from 'types/oauth2'

export const useSetupFlowAuthorizeLogic = () => {
  const dispatch = useAppDispatch()
  const redirect_uri = `${window.location.origin}/wizard/setup/v2/ipir/callback`
  const connection = OAuth2Connection.IPIR

  const { isCreateProcessSuccess, isCreateProcessFailed, authProcess } = useAppSelector(_store => ({
    isCreateProcessSuccess: isSuccess(_store.oauth2.api.createAuthenticationProcessApiStatus),
    isCreateProcessFailed: isFailed(_store.oauth2.api.createAuthenticationProcessApiStatus),
    authProcess: _store.oauth2.createAuthenticationProcessResponse
  }))

  useEffect(() => {
    const authProcessId = makeUuid()
    // TODO use real data
    const metadata = {
      mock_get_authorize_url: `${process.env.REACT_APP_GATEWAY_URL}/internal/oauth2/idp-callback?state=setup|${authProcessId}`,
      mock_handle_token_exchange: 'foobar'
    }
    dispatch(createAuthenticationProcess({ authProcessId, metadata }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isCreateProcessSuccess && authProcess) {
      window.location.href = `${process.env.REACT_APP_GATEWAY_URL}/internal/oauth2/authorize?auth_process_id=setup|${authProcess.id}&redirect_uri=${redirect_uri}&connection=${connection}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateProcessSuccess])

  useEffect(() => {
    if (isCreateProcessFailed) {
      routesConfig.SETUP_FLOW.goto(undefined, false, '?error=OAuth2AuthorizationCodeRequestFailed')
    }
  }, [isCreateProcessFailed])
}
