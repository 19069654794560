import { useCallback, useEffect, useMemo, useState } from 'react'

import { process, SortDescriptor, State } from '@progress/kendo-data-query'
import { GridDataStateChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'

import config from 'config/appConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { getRedeliveryQueueMessages, postRedeliverMessage, redeliverMessage } from 'redux/features/mstore/mstoreSlice'
import { formatDate } from 'lib/datetime'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import { RedeliverMessageResult } from 'types/Messages'

interface GridState {
  dataState: State
  dataResult: {
    total: number
    data: ModifiedResultConfig[]
  }
}

export interface ModifiedResultConfig extends RedeliverMessageResult {
  formattedQueueDate: string
  isChecked: boolean
}

export interface UseRedeliveryQueueLogic {
  isGetRedeliveryQueueLoading: boolean
  shouldDisableButton: boolean
  gridState: GridState
  sort: SortDescriptor[]
  handleSortChange: (e: GridSortChangeEvent) => void
  handlePageChange: (e: GridDataStateChangeEvent) => void
  retryStarted: boolean
  onRetry: () => void
  numChecked: number
  checkedAll: boolean
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void
  handleCheckAll: () => void
}

const dataState = {
  skip: 0,
  take: 10,
  sort: [{ field: 'from', dir: 'asc' }] as SortDescriptor[],
  group: [],
  collapsedGroups: []
}

export const useRedeliveryQueueLogic = (): UseRedeliveryQueueLogic => {
  const dispatch = useAppDispatch()
  const { redeliveryQueueData, isPostRedeliverSending, postRedeliverSuccess, isGetRedeliveryQueueLoading } =
    useAppSelector(_store => ({
      redeliveryQueueData: _store.mstore.redeliveryQueue,
      isPostRedeliverSending: isPending(_store.mstore.postRedeliveryApiStatus),
      postRedeliverSuccess: isSuccess(_store.mstore.postRedeliveryApiStatus),
      isGetRedeliveryQueueLoading: isPending(_store.mstore.getRedeliveryQueueApiStatus)
    }))

  const [checkedAll, setCheckedAll] = useState(false)
  const [numChecked, setNumChecked] = useState(0)
  const [hasQueuedMessage, setHasQueuedMessage] = useState(false)
  const [sort, setSort] = useState(dataState.sort)
  const [retryStarted, setRetryStarted] = useState(false)
  const isRedeliverRollout = isMyFeatureOn(FEATURES.EGD_React_Redeliver_Rollout)

  const initGridData = useMemo(
    () =>
      redeliveryQueueData?.results?.map(msg => ({
        ...msg,
        formattedQueueDate: formatDate(new Date(msg.queuedDate), config.DATETIME.REDELIVERY_QUEUE_DATE_FORMAT)
      })),
    [redeliveryQueueData]
  )

  const [gridState, setGridState] = useState<GridState>({
    dataState,
    dataResult: process(initGridData || [], dataState)
  })

  const shouldDisableButton = useMemo(() => !(numChecked > 0 && !hasQueuedMessage), [numChecked, hasQueuedMessage])

  useEffect(() => {
    if (!isPostRedeliverSending && postRedeliverSuccess) {
      setRetryStarted(false)
      setNumChecked(0)
      setCheckedAll(false)
      dispatch(getRedeliveryQueueMessages())
    }
  }, [dispatch, isPostRedeliverSending, postRedeliverSuccess])

  useMemo(() => {
    const gridData = (initGridData || []).map(item => ({
      isChecked: false,
      ...item,
      subject: decodeURIComponent(item.subject)
    }))
    setGridState({ ...gridState, dataResult: process(gridData, gridState.dataState) })
    // eslint-disable-next-line
  }, [redeliveryQueueData])

  const onRetry = useCallback(() => {
    setRetryStarted(true)
    if (isRedeliverRollout) {
      const redeliveryQueue = gridState.dataResult.data
        .filter(m => m.isChecked)
        .map(m => ({
          from: m.from,
          to: m.to.join(','),
          status: m.status,
          account_id: m.accountId,
          domain_id: String(m.domainId),
          subject: m.subject,
          message_id: m.messageId,
          header_from: m.headerFrom,
          description: m.description
        }))
      dispatch(
        redeliverMessage({
          items: redeliveryQueue
        })
      )
    } else {
      const redeliveryQueue = gridState.dataResult.data
        .filter(m => m.isChecked)
        .map(m => ({
          messageId: m.messageId,
          domainId: m.domainId
        }))
      dispatch(
        postRedeliverMessage({
          items: redeliveryQueue,
          atpBypass: false
        })
      )
    }
  }, [dispatch, gridState, isRedeliverRollout])

  const handleCheck = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
      let count = 0
      const data = gridState.dataResult.data.map(item => {
        const updatedItem = {
          ...item,
          ...(item.id === id && { isChecked: e.target.checked })
        }
        if (updatedItem.isChecked) count++
        return updatedItem
      })
      setHasQueuedMessage(data.some(item => item.isChecked && item.status.toLowerCase() === 'queued'))

      setGridState({ ...gridState, dataResult: process(data, gridState.dataState) })
      setNumChecked(count)
      setCheckedAll(count > 0 && count === gridState.dataResult.data.length)
    },
    [gridState]
  )

  const handleCheckAll = useCallback(() => {
    const checked = !checkedAll
    const data = gridState.dataResult.data.map(item => ({ ...item, isChecked: checked }))

    setHasQueuedMessage(data.some(item => item.isChecked && item.status.toLowerCase() === 'queued'))

    setCheckedAll(checked)
    setNumChecked(checked ? gridState.dataResult.data.length : 0)
    setGridState({ ...gridState, dataResult: process(data, gridState.dataState) })
  }, [gridState, checkedAll])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const handlePageChange = useCallback(
    (e: GridDataStateChangeEvent) => {
      setGridState({
        dataState: e.data,
        dataResult: process(initGridData || [], e.data)
      })
    },
    [initGridData]
  )

  return useMemo(
    () => ({
      isGetRedeliveryQueueLoading,
      shouldDisableButton,
      gridState,
      sort,
      handleSortChange,
      handlePageChange,
      retryStarted,
      onRetry,
      numChecked,
      checkedAll,
      handleCheck,
      handleCheckAll
    }),
    [
      isGetRedeliveryQueueLoading,
      shouldDisableButton,
      gridState,
      sort,
      handleSortChange,
      handlePageChange,
      retryStarted,
      onRetry,
      numChecked,
      checkedAll,
      handleCheck,
      handleCheckAll
    ]
  )
}
