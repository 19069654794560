import React, { useMemo } from 'react'

import { Grid, Typography } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'

import { useEmailFlowLogic } from 'components/pages/setupFlow/views/emailFlow/useEmailFlowLogic'
import styles from 'components/pages/setupFlow/views/emailFlow/emailFlowStyles'
import { EmailFlowSteps } from 'components/pages/setupFlow/types/egdWizardTypes'
import AddNewMxRecords from 'components/pages/setupFlow/views/emailFlow/sections/AddNewMxRecords'
import RemoveOldMxRecords from 'components/pages/setupFlow/views/emailFlow/sections/RemoveOldMxRecords'

const BASE_I18N_KEY = 'ess_wizard.email_flow'

const EmailFlow = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useEmailFlowLogic()

  return useMemo(
    () => (
      <Grid className={classes.container} container justifyContent="center" alignItems="flex-start">
        {/* CONTENT */}
        <Grid data-testid="content-wrapper" className={classes.contentWrapper} container direction="column">
          <Typography className={classes.title} variant="h2">
            {formatMessage('title')}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            {formatMessage('subtitle')}
          </Typography>
          {Object.entries(state.sectionsIsVerifiedStates).map((sectionState, idx) => {
            const [sectionName] = sectionState

            return (
              <Grid
                key={sectionName}
                data-testid={sectionName}
                className={classes.sectionWrapper}
                data-wizard-section={sectionName}
                container
                direction="row"
              >
                <Grid item xs>
                  <Grid className={classes.sectionHeading} container direction="column">
                    <Typography className={classes.sectionTitle} variant="h3">
                      {formatMessage(`section_${idx + 1}_title`)}
                    </Typography>
                    <Typography className={classes.sectionSubtitle} variant="body1">
                      {formatMessage(`section_${idx + 1}_subtitle`)}
                    </Typography>
                  </Grid>
                  <Grid className={classes.sectionContentWrapper} container>
                    {sectionName === EmailFlowSteps.addNewMxRecords && (
                      <AddNewMxRecords
                        sectionsVerifiedStates={state.sectionsIsVerifiedStates}
                        setIsAddMxRecordsSectionHasError={eventHandlers.setSectionVerifiedState(
                          EmailFlowSteps.addNewMxRecords,
                          eventHandlers.setIsAddMxRecordsSectionHasError
                        )}
                      />
                    )}
                    {sectionName === EmailFlowSteps.removeOldMxRecords && (
                      <RemoveOldMxRecords
                        sectionsVerifiedStates={state.sectionsIsVerifiedStates}
                        setIsRemoveOldMxRecordsSectionHasError={eventHandlers.setSectionVerifiedState(
                          EmailFlowSteps.removeOldMxRecords,
                          eventHandlers.setIsRemoveOldMxRecordsSectionHasError
                        )}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    ),
    [classes, state, eventHandlers, formatMessage]
  )
}

export default EmailFlow
