import React, { useMemo } from 'react'

import {
  Grid,
  Typography,
  Alert,
  SelectWrapper,
  SelectLabel,
  SelectMenuItem,
  FormHelperText
} from '@barracuda-internal/bds-core'
import Select from 'components/libs/bds/Select'

import { useFormatMessage } from 'lib/localization'

import { useRegionSelectionLogic } from 'components/pages/setupFlow/views/regionSelection/useRegionSelectionLogic'
import styles from 'components/pages/setupFlow/views/regionSelection/regionSelectionStyles'

const BASE_I18N_KEY = 'ess_wizard.region_selection'

const RegionSelection = () => {
  const classes = styles()
  const [state, eventHandlers] = useRegionSelectionLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Grid container justifyContent="center" alignItems="flex-start">
        <Grid container className={classes.contentWrapper} direction="column">
          <Typography className={classes.selectorTitle} variant="h3">
            {formatMessage('selector_title')}
          </Typography>
          <Typography className={classes.selectorSubtitle} variant="body1">
            {formatMessage('selector_subtitle')}
          </Typography>
          <Alert className={classes.selectorAlert} severity="warning">
            {formatMessage('selector_warning')}
          </Alert>
          <SelectWrapper variant="outlined" error={state.isMissedRegionError}>
            <SelectLabel>{formatMessage('region')}</SelectLabel>
            <Select
              disabled={state.isSetSelectedRegionInProgress}
              data-testid="region-selector"
              label={formatMessage('region')}
              onChange={eventHandlers.onSelectRegion}
              value={state.selectedRegion?.code || ''}
            >
              {state.countries.map(({ code, name }) => (
                <SelectMenuItem key={code} value={code}>
                  {name}
                </SelectMenuItem>
              ))}
            </Select>
            {state.isMissedRegionError && (
              <FormHelperText data-testid="missed-region-error">{formatMessage('missed_region_error')}</FormHelperText>
            )}
          </SelectWrapper>
        </Grid>
      </Grid>
    ),
    [state, eventHandlers, classes, formatMessage]
  )
}

export default RegionSelection
