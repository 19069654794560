/* eslint-disable quotes */
const YES = 'Sí'
const NO = 'No'

const domains = {
  header: {
    title: 'Gestor de Dominios',
    add_domain: 'Agregar Dominio',
    mail_flow: 'Mail Flow',
    domains: 'Domains'
  },
  table: {
    domain_name: 'Nombre de dominio',
    status: 'Estado',
    aliases: 'Alias',
    recipients: 'Destinatarios (últimos 30 días)',
    mail_servers: 'Servidores de correo',
    settings: 'Ajustes',
    domain_options: 'Opciones de dominio',
    no_records_available: 'No hay registros disponibles',
    no_mail_servers_defined: 'No hay servidores de correo definidos'
  },
  actions: {
    edit: 'Editar',
    manage: 'Gestione',
    remove: 'Eliminar',
    helpdesk: 'Servicio de asistencia'
  },
  status: {
    unverified: 'Sin verificar',
    verified: 'Dominio verificado, el correo fluye a través del registro MX',
    misconfigured: 'Dominio verificado. Los registros MX no apuntan a Barracuda',
    inline_verified:
      'Verificado, pero los registros MX apuntan a Barracuda. El despliegue en línea causará problemas de autenticación del remitente.',
    inline_misconfigured: 'Verificado'
  },
  mx_banner: {
    text: 'Los registros MX de uno o más dominios están mal configurados. Esto puede interferir con la capacidad de Barracuda Networks para proteger su(s) dominio(s) de amenazas y spam.',
    learn_more: 'Más información'
  },
  tooltip: {
    misconfigured:
      'Configure todos los registros MX de este dominio para que apunten a Barracuda Networks. Siga el enlace en el banner de arriba.',
    unique_settings: 'Políticas específicas de dominio'
  },
  enabled: 'Activado',
  disabled: 'Discapacitados',
  details: {
    mx_records_configuration: 'Configuración de registros MX',
    outbound_smarthost_configuration: 'Configuración de Smarthost de salida',
    aliases: 'Alias',
    email_continuity: 'Continuidad del correo electrónico',
    domain_specific_policies: 'Políticas específicas de dominio',
    reset_to_account_policies: 'Restablecer las políticas de cuentas',
    account_policies: 'Políticas de cuentas',
    primary: 'Principal',
    backup: 'Copia de seguridad',
    hostname: 'Nombre de host'
  },
  add_domain: {
    title: 'Agregar Dominio',
    domain_name: 'Nombre de Dominio',
    mail_server: 'Servidor de Correo',
    cancel: 'Cancelar',
    add: 'Agregar Dominio',
    invalid_domain: 'Introduzca un nombre de dominio válido.',
    missing_mail_server: 'Por favor, introduzca al menos un host para su servidor de correo.',
    invalid_mail_server: 'Nombre de host no válido',
    tld_warning: 'El nombre de dominio introducido no tiene un TLD. Está seguro de que desea añadir este dominio?',
    blacklisted_domain_name: 'Este nombre de dominio no se puede añadir.'
  },
  add_mail_server: {
    title: 'Agregar Servidor de Correo',
    mail_server: 'Servidor de Correo',
    preference: 'Prioridad',
    cancel: 'Cancelar',
    add: 'Agregar Servidor de Correo'
  },
  delete_domain_confirm: {
    title: 'Se requiere confirmación',
    message:
      '¿Está seguro de que desea eliminar este dominio? Eliminar un dominio eliminará todas las configuraciones asociadas y el estado de verificación.',
    outbound_ip_conflict: 'Por favor, elimine o cambie la entrada de registro de salida para esta IP.',
    only_verified_domain: 'Este dominio ya no podrá enviar correo electrónico saliente. ¿Continuar?'
  },
  edit_domain: {
    header: {
      title: 'Configuración del dominio'
    },
    mail_servers: {
      title: 'Servidores de correo',
      description:
        'Si se especifica más de un servidor de correo, se intentará acceder a cada uno de ellos por orden de prioridad.',
      address: 'Servidor de correo (dirección IP o nombre de host.)',
      priority: 'Prioridad',
      test: 'Prueba',
      edit: 'Editar',
      remove: 'Eliminar',
      add: 'Añadir',
      cancel: 'Cancelar',
      update: 'Actualización',
      mx_server_configuration: 'Configuración del servidor MX',
      primary: 'Principal',
      backup: 'Copia de seguridad',
      outbound_smarthost_configuration: 'Configuración del smarthost de salida',
      hostname: 'Nombre de host',
      empty_host: 'Por favor, introduzca al menos un host para su servidor de correo.',
      invalid_host: 'Nombre de host no válido',
      invalid_preference: 'Prioridad no válida.',
      send: 'Enviar',
      empty_recipient: 'Introduzca el nombre del destinatario.',
      email_send_failure: 'Error al enviar el correo de prueba a este destinatario',
      test_configuration: 'Configuración de prueba',
      confirm_removal: 'Confirmar eliminación',
      confirm_removal_body: '¿Está seguro de que desea eliminar <span>{serverName}</span>?'
    },
    save: 'Guardar',
    cancel: 'Cancelar',
    alias: {
      title: 'Alias de dominio'
    },
    domain_select_label: 'Seleccionar dominio',
    domain_select_description: 'Convertir este dominio en un alias de',
    domain_select_loading: 'Cargando dominios disponibles...',
    domain_select_empty_value: 'Ninguno',
    domain_select_helper: 'NO debe utilizar alias de dominio y alias de usuario al mismo tiempo.',
    domain_select_error: 'No se han podido cargar los dominios disponibles',
    options: {
      title: 'Opciones',
      yes: YES,
      no: NO,
      spooling: {
        label: 'Carrete'
      },
      user_auto_add: {
        label: 'Añadir usuarios automáticamente',
        helper:
          'Nota: Para evitar la creación de usuarios no válidos, active la verificación de destinatarios en su servidor de correo.'
      },
      enable_sender_proof_protection: {
        label: 'Activar la protección contra suplantación de remitente'
      },
      rewrite_recipients_for_aliased_domains: {
        label: 'Reescribir destinatarios para dominios alias'
      }
    },
    directory_services: {
      title: 'Servicios de directorio',
      description:
        'Puede utilizar los servicios de directorio Azure AD o LDAP para permitir el inicio de sesión único y la sincronización de las listas de usuarios.',
      type: {
        label: 'Tipo'
      },
      types: {
        none: 'Ninguno',
        azure_ad: 'Azure AD',
        ldap: 'LDAP'
      },
      azure_ad: {
        status: 'Estado',
        not_authorized: 'No autorizado',
        authorize: 'Autorizar',
        authorized: 'Activo',
        revoke_authorization: 'Revocar la autorización',
        tenant_status_error: 'No se ha podido consultar el estado del inquilino',
        authorized_account: 'Cuenta autorizada',
        authorization_date: 'Fecha de autorización',
        directory_options: 'Opciones de directorio',
        synchronization_options: {
          label: 'Opciones de sincronización',
          manual: 'Manual',
          automatic: 'Sincronizar automáticamente'
        },
        sync_now: 'Sincronizar ahora',
        sync_stats:
          '{sync_users_added} usuarios añadidos, {sync_users_updated} usuarios actualizados, {sync_users_deleted} usuarios eliminados',
        sync_status: {
          hidden: ' ',
          currently_in_progress: 'La sincronización está en curso, {sync_stats}',
          completed: 'Sincronización finalizada en {sync_finished_at}, {sync_stats}',
          already_in_progress: 'Sincronización actual',
          still_syncing: 'Sincronización aún en curso, {sync_stats} hasta ahora'
        },
        sso: {
          label: 'Activar el inicio de sesión único',
          yes: YES,
          no: NO
        },
        test_azure_ad_settings: {
          title: 'Probar los ajustes de configuración de Azure AD',
          label: 'Dirección de correo electrónico de prueba',
          helper: {
            note: 'Sólo para probar la configuración de Azure AD. Introduzca una dirección de correo electrónico principal válida.'
          },
          button: 'Ajustes de prueba',
          empty_username: 'Introduzca una dirección de correo electrónico para probar',
          invalid_username: 'Introduzca sólo la parte del nombre de usuario de la dirección de correo electrónico'
        }
      },
      ldap: {
        ldap_configuration: {
          title: 'Configuración LDAP',
          host: 'Host LDAP',
          port: 'Port',
          use_ssl: 'Utilizar SSL (LDAPS)',
          use_ssl_yes: YES,
          use_ssl_no: NO,
          ldap_username: 'Vincular DN/nombre de usuario',
          ldap_password: 'Contraseña',
          ldap_base_dn: 'Base DN',
          ldap_base_dn_helper:
            "DN base de su directorio. Puede sustituirlo por el siguiente valor: '{default_naming_context}': Si su dominio es test.com, su DN Base podría ser DC=test,DC=com.",
          ldap_mail_attributes: 'Atributos de correo',
          ldap_mail_attributes_helper:
            'Lista de atributos separados por comas que contiene las direcciones de correo electrónico. Ejemplos: mail,sAMAccountName,proxyaddresses',
          test_config: 'Probar la configuración LDAP',
          testing_email_address: 'Dirección de correo electrónico de prueba',
          testing_email_address_helper:
            'Sólo para probar la configuración LDAP. Introduzca una dirección de correo electrónico principal válida. Si está en blanco, las pruebas se limitarán a la conexión.',
          test_settings: 'Ajustes de prueba'
        },
        directory_options: {
          title: 'Opciones de directorio',
          ldap_sync: 'Sincronizar automáticamente',
          ldap_sync_yes: YES,
          ldap_sync_no: NO,
          ldap_sync_helper: 'Sincronización finalizada más recientemente: {last_ldap_sync}',
          sync_now: 'Sincronizar ahora',
          ldap_auth: 'Utilizar LDAP para la autenticación',
          ldap_auth_yes: YES,
          ldap_auth_no: NO,
          auth_filter: 'Filtro de autenticación',
          auth_filter_helper: {
            info: 'Especifique la cadena de filtrado LDAP para la autenticación de usuarios de correo electrónico. Puede sustituir los siguientes valores:',
            email_address: "'{email_address}': La dirección de correo electrónico completa introducida por el usuario.",
            user_name:
              "{user_name}': La parte del nombre de usuario de la dirección de correo electrónico o la entrada completa si el usuario no introdujo una dirección de correo electrónico.",
            domain_name: "{domain_name}': La parte del dominio de la dirección de correo electrónico.",
            example:
              "Ejemplo: (|(mail='{email_address}')(userPrincipalName='{user_name}')(sAMAccountName='{user_name}'))"
          }
        },
        advanced_configuration: {
          title: 'Configuración LDAP avanzada',
          domain_limited: 'Filtro de usuario',
          domain_limited_yes: YES,
          domain_limited_no: NO,
          user_filter: 'Filtro de usuario personalizado',
          user_filter_helper: {
            info: 'Especifique la cadena de filtrado LDAP (opcional) para la sincronización de usuarios de correo electrónico.',
            example: 'Ejemplo: (name=*test*)'
          }
        },
        errors: {
          ldap_sync_and_user_auto_add_conflict:
            'No puede utilizar la Creación automática de usuarios y la Sincronización automática LDAP al mismo tiempo. Por favor, active sólo una opción.',
          ldap_password_missing: 'Por favor, introduzca la nueva contraseña LDAP'
        },
        test: {
          title: 'Prueba de configuración LDAP',
          in_progress: 'Se está probando la configuración LDAP...',
          no_host_specified: 'No se ha especificado el host',
          no_bind_dn_specified: 'No se ha especificado el DN de enlace',
          no_password_specified: 'No se ha especificado la contraseña de enlace',
          no_connection: 'Sin conexión',
          bind_failed: 'Error de enlace',
          unknown_error: 'Se ha producido un error',
          connection_successful: 'Conexión correcta',
          user_lookup_not_executed: 'Búsqueda de usuario no ejecutada',
          user_email_lookup_empty: 'La búsqueda de usuario (email de prueba) devuelve un resultado vacío',
          user_filter_lookup_empty: 'La búsqueda de usuarios (filtro de usuarios) devuelve un resultado vacío',
          user_lookup_successful: 'Búsqueda de usuario correcta',
          user_query_title: 'La consulta al usuario {test_email} devuelve los siguientes atributos',
          user_filter_title: 'Primera entrada encontrada con filtro de usuario'
        }
      }
    },
    smtp_encryption: {
      title: 'Cifrado SMTP',
      force_tls_label: 'Utilice TLS desde Barracuda Networks a su(s) servidor(es) de correo:',
      tls_label: 'Requerir TLS a Barracuda Networks desde estos dominios:',
      never: 'Nunca',
      optional: 'Opcional',
      required: 'Requerido',
      smtp_over_tls: 'SMTP sobre TLS',
      all_inbound_messages: 'Todos los mensajes entrantes',
      selected_sender_domains: 'Seleccionar dominios de remitente',
      save_changes_helper: 'Guardar los cambios para habilitar la edición masiva',
      table_description_all:
        'Para forzar a Email Gateway Defense a requerir una conexión TLS segura desde todos los dominios excepto un determinado dominio de remitente, introduzca el nombre del dominio de remitente exento y haga clic en Añadir.',
      table_description_select:
        'Para forzar a Email Gateway Defense a exigir una conexión TLS segura a determinados dominios de remitente, introduzca el nombre del dominio del remitente y haga clic en Agregar.',
      table_sub_description:
        'Si no se puede establecer una conexión TLS, el correo no se envía al dominio del destinatario.',
      bulk_edit: 'Edición masiva'
    },
    encryption: {
      title: 'Cifrado',
      validation_status: 'Estado de validación',
      validate_cname: 'Validar CNAME',
      validate_post: 'Validar Postmaster',
      remove_validation: 'Eliminar validación',
      confirm_validation: 'Confirmar Validación',
      unable_to_connect: 'No se puede conectar al servicio de cifrado.',
      validated: 'Validado',
      not_validated: 'No validado',
      pending_cname: 'Pendiente de validación CNAME con {value}',
      pending_post: 'Pendiente de validación postal, por favor compruebe postmaster@{value}',
      upload_image: 'Cargar nueva imagen:',
      choose_file: 'Elegir archivo',
      no_file: 'Ningún archivo elegido',
      remove_file: 'Eliminar logotipo actual',
      upload_image_helper:
        'Imagen de marca para este dominio. Formato de archivo requerido: JPG, GIF o PNG. Resolución recomendada: 160x65 píxeles',
      allow_replies: 'Permitir respuestas:',
      read_receipts: 'Activar recibos de lectura:',
      display_name: 'Nombre de dominio:',
      display_name_helper: 'Este nombre de dominio aparecerá en los mensajes encriptados.',
      notification_subject: 'Asunto de la notificación:',
      notification_subject_helper:
        'Asunto de las notificaciones de cifrado enviadas a los destinatarios. Puede utilizar los mismos marcadores de posición que las partes de correo electrónico a continuación.',
      text_part: 'Parte de texto:',
      text_part_helper:
        'La parte de texto de la notificación personalizada que se enviará a los destinatarios. Puede utilizar los mismos marcadores de posición que la parte HTML a continuación.',
      html_part: 'Parte HTML:',
      html_part_helper: 'La parte HTML de la notificación personalizada que se enviará a los destinatarios.'
    },
    warnings: {
      disable_spooling: {
        title: 'Continuidad del correo electrónico',
        description:
          'Al desactivar la función de cola de impresión se desactivará la activación automática de Email Continuity para todos los dominios asociados a esta cuenta.'
      },
      disable_azure: {
        title: 'Desactivar Azure AD',
        description: {
          info: 'La desactivación de Azure AD tendrá las siguientes consecuencias:',
          consequences: {
            sso: 'El inicio de sesión único con Barracuda ESS ya no funcionará',
            sync: 'Los nuevos usuarios no se sincronizarán automáticamente'
          },
          note: 'Nota: La verificación del destinatario seguirá funcionando.'
        }
      },
      azure_authorization: {
        title: 'Autorizar Azure AD',
        description: {
          info: 'Para permitir el servicio de directorio Azure AD para el inicio de sesión único y la sincronización de listas de usuarios, debe autenticarse con Office 365 con una cuenta de administrador.',
          continue: 'Si continúa, accederá a Office 365 para iniciar sesión.',
          permissions: {
            label: 'También debe autorizar a Email Gateway Defense a',
            user_profile: 'Iniciar sesión y leer el perfil de usuario',
            all_groups_read: 'Leer todos los grupos',
            all_groups_write: 'Leer y escribir todos los grupos',
            directory_data_read: 'Leer datos del directorio',
            directory_data_write: 'Leer y escribir datos de directorio',
            all_users_profile_read: 'Leer los perfiles completos de todos los usuarios',
            all_users_profile_write: 'Leer y escribir los perfiles completos de todos los usuarios'
          }
        }
      }
    },
    advanced: {
      title: 'Configuraciones avanzadas',
      srs: {
        title: 'Esquema de reescritura del remitente (SRS):',
        yes: YES,
        no: NO
      },
      ldap_highest_serial: {
        title: 'Sincronizar automáticamente:',
        value: 'Serie más alta vista: {value}',
        reset: 'Restablecer'
      }
    }
  },
  verify_domain: {
    header: {
      title: 'Verificación de dominios'
    },
    content:
      'Los dominios deben verificarse mediante una prueba de propiedad antes de poder utilizar Email Gateway Defense para filtrar el correo electrónico.',
    options: {
      mx_records: {
        label:
          '<b>Registros MX</b> - Seleccione este método si ha AÑADIDO el registro MX al servidor DNS de su dominio. Es importante que añada los registros con una prioridad MENOR (p. ej.: 99).',
        helper: 'Registros MX'
      },
      cname_record: {
        label:
          '<b>Registros CNAME</b> - Validar utilizando la entrada CNAME proporcionada y apuntar a ess.barracuda.com.',
        helper: 'Registros CNAME'
      },
      postmaster_email: {
        label: '<b>Correo electrónico al administrador de correos</b> - Envíe un correo electrónico de verificación a'
      },
      tech_email: {
        label:
          '<b>Correo electrónico al contacto técnico del dominio</b>: envíe un correo electrónico de verificación a la dirección de correo electrónico del contacto técnico que aparece en la entrada WHOIS del dominio.',
        no_record: 'No se ha encontrado ningún contacto técnico'
      }
    },
    token_verification: 'La verificación del dominio está en curso...',
    cpl_probe: 'Iniciando la verificación del dominio CPL...',
    already_verified: 'El dominio {domainName} ya está verificado.',
    already_verified_by_other_account:
      'Este dominio ya ha sido añadido y verificado por otra cuenta. Si este es su dominio, póngase en contacto con el Servicio de Atención al Cliente para obtener más ayuda.',
    ok: 'OK',
    next: 'Siguiente',
    cancel: 'Cancelar'
  },
  search_domain: {
    label: 'Buscar en',
    hint: 'Buscar texto en nombres de dominio'
  },
  recipients: {
    header: {
      title: 'Lista de destinatarios'
    },
    content: 'Destinatarios únicos vistos en los últimos 30 días',
    go_back: '« Volver a los dominios',
    search_label: 'Buscar en',
    search_hint: 'Buscar un destinatario o un conjunto de destinatarios específicos',
    recipient: 'Destinatario',
    no_records_available: 'Ningún destinatario único en los últimos 30 días',
    no_recipients_found: '<b>{userIdFilter}</b> no se ha encontrado en la lista de destinatarios únicos'
  },
  global_reset: {
    title: 'Confirmación necesaria',
    description: 'Confirmar el restablecimiento de las políticas de cuenta'
  },
  save_setting: {
    inline_deployment: {
      description: 'Método de despliegue',
      caption: 'La forma en que su correo electrónico fluye a través de Barracuda',
      microsoft_info:
        'Para completar la implementación en línea con Microsoft, debe asegurarse de que la configuración de Microsoft en reconfigurado para apuntar a Barracuda',
      learn_more: 'Más información'
    },
    options: {
      microsoft: 'En línea con Microsoft',
      off: 'Registros MX'
    },
    save: 'Guardar',
    cancel: 'Cancelar'
  },
  type_hybrid: 'Híbrido',
  transfer_pending: 'Transferencia pendiente',
  transfer_completed: 'Transferencia completada',
  verify_ownership_and_transfer: 'Verificar la propiedad y la transferencia',
  verify_domain_transfer: {
    title: 'Verificar la transferencia de dominio',
    info: 'El dominio que intenta añadir ya existe en nuestro sistema. Para permitir una transferencia a esta cuenta, verifique que es el propietario del dominio.',
    cname_record_info: 'En el DNS de su dominio, añada un registro CNAME con el siguiente contenido:',
    hostname: '<b>Nombre de host</b>',
    points_to: '<b>Señala</b>',
    long_running_info:
      'La transferencia del dominio puede tardar varios minutos en completarse. Actualice la lista de la página Administrador de dominios si la transferencia no parece haberse completado.',
    cancel: 'Cancelar',
    verify: 'Verificar la propiedad y la transferencia',
    back_to_domains: 'Volver a dominios',
    error: {
      domain_transfer_not_found: 'No se encuentra el registro CNAME necesario para este dominio.',
      domain_not_found: 'No se encuentra este dominio',
      domain_not_verified:
        'Este dominio no está verificado. Debe estar verificado para poder transferirlo a otra cuenta.',
      get_move_domain_failed: 'Se ha producido un error al intentar recuperar el estado de la transferencia',
      domain_transfer_already_complete: 'Este dominio ya pertenece a esta cuenta.',
      verify_domain_failed: 'Se ha producido un error al intentar verificar el dominio',
      graphql_error:
        'No se ha podido contactar con el servidor para completar el traslado de dominio, inténtelo de nuevo.',
      cname_record_not_found:
        'Actualmente, no se puede encontrar el registro CNAME correcto para su dominio. Compruebe que la entrada se ha añadido a sus registros DNS. Tenga en cuenta que cualquier cambio puede tardar hasta 24 horas en propagarse, dependiendo de la configuración de su proveedor de Internet.',
      transfer_pending: 'El dominio ya está siendo transferido.',
      mstore_error: 'La operación ha devuelto un error: {message}',
      mstore_timeout: 'La operación ha finalizado'
    }
  }
}

/* eslint-enable quotes */
export default domains
