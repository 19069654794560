import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import routesConfig, { gotoAdminEntryPathFromWizard } from 'lib/routesConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getEmailServerSettings, setSetupFlowStep } from 'redux/features/emailServer/emailServerSlice'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { SetupFlowSteps } from './types/egdWizardTypes'

interface Steps {
  completed: boolean
  active: boolean
  text: string
}

export interface State {
  error: string | null
  steps: Steps[]
  currentStep: SetupFlowSteps
  isGetEmailServerSettingsInProgress: boolean
  isGetEmailServerSettingsIsSuccess: boolean
  isGetEmailServerSettingsFailed: boolean
}

export interface EventHandlers {
  onExit: () => void
  onNext: () => void
}

export type EmailFlowLogic = [State, EventHandlers]

export const useSetupFlowLogic = (): EmailFlowLogic => {
  const dispatch = useAppDispatch()
  const [params] = useSearchParams()
  const error = params.get('error')

  const isSetupFlowOn = isMyFeatureOn(FEATURES.EGD_IP_IR_Converged_Setup_Rollout)

  const {
    currentStep,
    isGetEmailServerSettingsInProgress,
    isGetEmailServerSettingsIsSuccess,
    isGetEmailServerSettingsFailed
  } = useAppSelector(_store => ({
    currentStep: _store.emailServer.setupFlowStep,
    isGetEmailServerSettingsInProgress: isPending(_store.emailServer.getEmailServerSettingsApiStatus),
    isGetEmailServerSettingsIsSuccess: isSuccess(_store.emailServer.getEmailServerSettingsApiStatus),
    isGetEmailServerSettingsFailed: isFailed(_store.emailServer.getEmailServerSettingsApiStatus)
  }))

  // init
  useEffect(
    () => {
      dispatch(getEmailServerSettings())
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    if (!isSetupFlowOn) {
      routesConfig.ONBOARD_WIZARD.goto()
    }
  }, [isSetupFlowOn])

  const onExit = useCallback(() => {
    // TODO: implementation for bringing the user back from where he started the wizard
    console.log('onExit clicked')
  }, [])

  const onNext = useCallback(async () => {
    switch (currentStep) {
      case SetupFlowSteps.introduction:
        // TODO: auth link from IP/IR
        routesConfig.SETUP_FLOW_IPIR_AUTHORIZE.goto()
        return
      case SetupFlowSteps.microsoftConnected:
        dispatch(setSetupFlowStep(SetupFlowSteps.regionSelection))
        return
      case SetupFlowSteps.regionSelection:
        dispatch(setSetupFlowStep(SetupFlowSteps.verifyDomain))
        return
      case SetupFlowSteps.verifyDomain:
        dispatch(setSetupFlowStep(SetupFlowSteps.deploymentSetup))
        return
      case SetupFlowSteps.deploymentSetup:
        dispatch(setSetupFlowStep(SetupFlowSteps.emailFlow))
        return
      case SetupFlowSteps.emailFlow:
        gotoAdminEntryPathFromWizard('')
        break
      default:
    }
  }, [dispatch, currentStep])

  const steps = useMemo(() => {
    let activeStepFound = false
    const result: Steps[] = []
    Object.keys(SetupFlowSteps).forEach(step => {
      if (step === currentStep) {
        result.push({
          text: `${step}_step`,
          active: true,
          completed: false
        })
        activeStepFound = true
      } else if (!activeStepFound) {
        result.push({
          text: `${step}_step`,
          active: false,
          completed: true
        })
      } else {
        result.push({
          text: `${step}_step`,
          active: false,
          completed: false
        })
      }
    })
    return result
  }, [currentStep])

  return useMemo(
    () => [
      {
        error,
        currentStep,
        steps,
        isGetEmailServerSettingsInProgress,
        isGetEmailServerSettingsIsSuccess,
        isGetEmailServerSettingsFailed
      },
      {
        onExit,
        onNext
      }
    ],
    [
      error,
      currentStep,
      steps,
      isGetEmailServerSettingsInProgress,
      isGetEmailServerSettingsIsSuccess,
      isGetEmailServerSettingsFailed,
      onExit,
      onNext
    ]
  )
}
