import { EmailServer } from 'types/domains'
import { SetupFlowSteps } from 'components/pages/setupFlow/types/egdWizardTypes'

export interface EmailServerConfig {
  server: string
  port: number | string
  domainId?: number
  domainName?: string
  verified?: boolean
  preference?: number
}

export type EmailServerResult = EmailServerConfig

export interface Provider {
  criteria: string
  providerName: string
  shortName: string
  url: string
}

export interface MxRecord {
  domain: string
  priority: number
  verified: boolean
}

export interface MxRecords {
  primary: MxRecord
  backup: MxRecord
}

export type OldMxRecords = MxRecord[]

export interface EmailServerSettings {
  setupFlowStep?: SetupFlowSteps
  region: string | null
  url: string | null
  email: string | null
  primaryMx: MxRecord | null
  backupMx: MxRecord | null
  emailServers: EmailServerConfig[] | null
  wizardCompleted: boolean
  provider: Provider[] | null
  oldMxRecords: MxRecord[] | null
  migrateToken: string
}

export enum EmailServerType {
  A = 'a',
  MX = 'mx'
}

export interface AddMailServerRequest {
  domainId: string
  host: string
  preference: number
  type: EmailServerType
}

export type AddMailServerResponse = EmailServer

export interface RemoveMailServerRequest {
  domainId: string
  serverId: string
}

export type RemoveMailServerResponse = string

export interface UpdateMailServerRequest extends AddMailServerRequest {
  serverId: string
}

export type UpdateMailServerResponse = EmailServer

export interface TestMailServerRequest {
  domainId: string
  serverId: string
  recipient: string
  domainName: string
}

export type TestMailServerResponse = void
