import { createStyles, makeStyles, colors } from '@barracuda-internal/bds-core'

const ACTION_ITEM_WIDTH = 133
const INPUT_WIDTH = 561
const CONTENT_WIDTH = 800

export default makeStyles(theme => {
  const TABLE_MARGIN = theme.spacing(2)
  const ACTION_ITEM_CONTENT_WIDTH = TABLE_MARGIN + ACTION_ITEM_WIDTH

  const DEFAULT_STYLES = createStyles({
    table: {
      marginRight: TABLE_MARGIN
    },
    tableRow: {
      color: colors.scaleGray900,
      boxShadow: `inset 0px -1px 0px ${colors.scaleGray100}`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    actionWrapper: {
      minWidth: ACTION_ITEM_WIDTH
    },
    alertWidth: {
      '@media screen and (min-width:681px)': {
        width: `calc(100% - ${ACTION_ITEM_CONTENT_WIDTH}px)`
      },
      '@media screen and (max-width:680px)': {
        width: '100%'
      }
    }
  })

  return createStyles({
    title: {
      color: colors.scaleGray900
    },
    subtitle: {
      color: colors.scaleGray600
    },
    content: {
      marginTop: theme.spacing(6)
    },
    container: {
      marginBottom: theme.spacing(10),
      '@media screen and (min-width:1181px)': {
        marginTop: theme.spacing(3)
      }
    },
    contentWrapper: {
      width: CONTENT_WIDTH,
      '@media screen and (min-width:1181px)': {
        marginLeft: theme.spacing(6)
      },
      '@media screen and (max-width:1180px)': {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
      }
    },
    inputWrapper: {
      maxWidth: INPUT_WIDTH,
      flex: 1,
      marginRight: theme.spacing(2)
    },
    tableWrapper: {
      ...DEFAULT_STYLES.table,
      flex: 1,
      marginTop: theme.spacing(2),
      '@media screen and (max-width:680px)': {
        width: '100%',
        marginRight: 0
      }
    },
    tableHeader: {
      ...DEFAULT_STYLES.tableRow,
      height: theme.spacing(4)
    },
    alertWrapper: {
      display: 'flex',
      marginBottom: theme.spacing(3)
    },
    alertTopWrapper: {
      display: 'flex',
      marginTop: theme.spacing(3)
    },
    alertTitle: {
      fontSize: 16,
      fontWeight: 500
    },
    tableRow: {
      ...DEFAULT_STYLES.tableRow,
      minHeight: theme.spacing(8)
    },
    erroredTableRow: {
      ...DEFAULT_STYLES.tableRow,
      height: theme.spacing(9.25)
    },
    serverNameTextField: {
      paddingRight: theme.spacing(1)
    },
    linkButton: {
      fontSize: 14
    },
    verifyButtonWrapper: {
      ...DEFAULT_STYLES.actionWrapper,
      flex: '0 1 auto',
      '@media screen and (max-width:680px)': {
        order: 3
      }
    },
    verifyButton: {
      marginTop: theme.spacing(7.5),
      '@media screen and (max-width:680px)': {
        marginTop: theme.spacing(2)
      }
    },
    erroredVerifyButton: {
      marginTop: theme.spacing(8)
    },
    infoWrapper: {
      marginTop: theme.spacing(2),
      ...DEFAULT_STYLES.alertWidth
    }
  })
})
