import { makeStyles } from '@barracuda-internal/bds-core'
import { commonStyles } from 'components/pages/onboardWizard/onboardWizardStyles'

const CONTENT_WIDTH = 514

export default makeStyles(theme => ({
  '@global': commonStyles['@global'],
  title: commonStyles.title,
  subtitle: commonStyles.subtitle,
  contentWrapper: {
    width: CONTENT_WIDTH,
    padding: `${theme.spacing(3)}px 0px 0px`
  },
  selectorTitle: {
    ...commonStyles.title,
    marginBottom: theme.spacing(0.5)
  },
  selectorSubtitle: commonStyles.subtitle,
  selectorAlert: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}))
