import { Box, Chip, Typography, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { useMemo } from 'react'
import { useFormatMessage } from 'lib/localization'
import SetupFlowStepsContentContainer from '../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../components/ContentTitleComponent'

const BASE_I18N_KEY = 'ess_wizard.introduction'

const Introduction: React.FC = () => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <SetupFlowStepsContentContainer>
        <ContentTitleComponent title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
        <img alt="" src="/images/email_security_visual-v1.svg" />
        <Box
          sx={{ paddingRight: tokens.aliasesSpaceContainerDefault, paddingLeft: tokens.aliasesSpaceContainerDefault }}
        >
          <Chip
            sx={{ width: '200px', maxWidth: '200px', gap: tokens.chipSizeMediumSpaceBetween }}
            bdsSize="medium"
            startIcon={<img alt="" style={{ marginLeft: 0 }} src="/images/microsoft_logo_icon.svg" />}
            label="Microsoft 365 Global Admin required"
            bdsType="staticSubtle"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: tokens.aliasesSpaceContainerDefault,
            paddingRight: tokens.aliasesSpaceContainerDefault,
            paddingLeft: tokens.aliasesSpaceContainerDefault
          }}
        >
          <Typography
            sx={{
              fontFamily: tokens.aliasesFontFamilyDefault,
              fontSize: tokens.aliasesFontSizeDefault,
              fontWeight: tokens.aliasesFontWeightDefault,
              color: tokens.aliasesColorTextStaticEmphasis,
              lineHeight: tokens.aliasesLineHeightDefault
            }}
          >
            {formatMessage('content_1')}
          </Typography>
          <Typography
            sx={{
              fontFamily: tokens.aliasesFontFamilyDefault,
              fontSize: tokens.aliasesFontSizeDefault,
              fontWeight: tokens.aliasesFontWeightDefault,
              color: tokens.aliasesColorTextStaticEmphasis,
              lineHeight: tokens.aliasesLineHeightDefault
            }}
          >
            {formatMessage('content_2')}
          </Typography>
          <Typography
            sx={{
              fontFamily: tokens.aliasesFontFamilyDefault,
              fontSize: tokens.aliasesFontSizeDefault,
              fontWeight: tokens.aliasesFontWeightDefault,
              color: tokens.aliasesColorTextStaticEmphasis,
              lineHeight: tokens.aliasesLineHeightDefault
            }}
          >
            {formatMessage('content_3')}
          </Typography>
        </Box>
      </SetupFlowStepsContentContainer>
    ),
    [formatMessage, tokens]
  )
}

export default Introduction
