import { Box, useTokens } from '@barracuda-internal/bds-core-v2'
import React, { PropsWithChildren, useMemo } from 'react'

const SetupFlowContentContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const tokens = useTokens()

  return useMemo(
    () => (
      <Box
        data-testid="SetupFlowContentContainer"
        sx={{
          flexGrow: 1,
          width: '906px',
          display: 'flex',
          gap: tokens.aliasesSpaceContainerSpacious
        }}
      >
        {children}
      </Box>
    ),
    [children, tokens.aliasesSpaceContainerSpacious]
  )
}

export default SetupFlowContentContainer
