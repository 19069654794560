import { useEffect, useMemo } from 'react'

import { EmailLogLanguage, IEmailLogApiClient } from '@barracuda-internal/converged-email-log'
import { useAppSelector } from 'redux/toolkit/hooks'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'
import { useEmailLogApiClient } from 'components/pages/emailLog/useEmailLogApiClient'
import { Language } from 'types/Language'

export enum UiState {
  INITIALIZING,
  READY
}

export interface UseEmailLogLogic {
  uiState: UiState
  bccAccountId: string
  emailLogApiClient: IEmailLogApiClient
  emailLogLanguage: EmailLogLanguage
}

export const useEmailLogLogic = (): UseEmailLogLogic => {
  const { appTypeEntryPath } = useAppTypeEntryPaths()
  const { bccAccountId, isEmailLogEnabled, language } = useAppSelector(_stores => ({
    bccAccountId: _stores.auth.accessTokenObject?.bccAccountId || '',
    isEmailLogEnabled: _stores.auth.accessTokenObject?.features?.isEmailLogEnabled,
    language: _stores.app.language
  }))
  const emailLogApiClient = useEmailLogApiClient()

  const uiState = useMemo(() => {
    switch (true) {
      case typeof isEmailLogEnabled !== 'undefined':
        return UiState.READY
      default:
        return UiState.INITIALIZING
    }
  }, [isEmailLogEnabled])

  const emailLogLanguage = useMemo(() => {
    switch (true) {
      case language === Language.japanese:
        return EmailLogLanguage.JP
      case language === Language.spanish:
        return EmailLogLanguage.ES
      case language === Language.english:
      default:
        return EmailLogLanguage.EN
    }
  }, [language])

  useEffect(() => {
    if (isEmailLogEnabled === false) {
      appTypeEntryPath.goto()
    }
  }, [appTypeEntryPath, isEmailLogEnabled])

  return useMemo(
    () => ({
      uiState,
      bccAccountId,
      emailLogApiClient,
      emailLogLanguage
    }),
    [uiState, bccAccountId, emailLogApiClient, emailLogLanguage]
  )
}
