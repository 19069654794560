import { createStyles, makeStyles } from '@barracuda-internal/bds-core'
import { commonStyles } from 'components/pages/onboardWizard/onboardWizardStyles'

const CONTENT_WIDTH = 800
const SECTION_HEADING_WIDTH = 570

export default makeStyles(theme =>
  createStyles({
    title: commonStyles.title,
    subtitle: commonStyles.subtitle,
    container: {
      marginBottom: theme.spacing(10),
      '@media screen and (min-width:1181px)': {
        marginTop: theme.spacing(3)
      }
    },
    contentWrapper: {
      width: CONTENT_WIDTH,
      '@media screen and (min-width:1181px)': {
        marginLeft: theme.spacing(6)
      },
      '@media screen and (max-width:1180px)': {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
      }
    },
    sectionWrapper: {
      marginTop: theme.spacing(6),
      '@media screen and (max-width:680px)': {
        flexWrap: 'nowrap'
      }
    },
    sectionHeading: {
      width: SECTION_HEADING_WIDTH,
      '@media screen and (max-width:680px)': {
        width: 'auto'
      }
    },
    sectionTitle: {
      ...commonStyles.title,
      marginBottom: theme.spacing(0.5)
    },
    sectionSubtitle: commonStyles.subtitle,
    sectionContentWrapper: {
      marginTop: theme.spacing(3)
    }
  })
)
