import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosPromise } from 'axios'
import { objectToCamel, objectToSnake } from 'ts-case-convert'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'

import { setErrorSnackBar, setSuccessSnackBar, setWarningSnackBar } from 'redux/features/app/appSlice'
import {
  MlogViewType,
  removeListItems,
  resetSelectedMessage,
  setDownloadAttachmentProgress,
  setDownloadMessageProgress
} from 'redux/features/mstore/mstoreActions'
import {
  Action,
  ActionMessageInfo,
  ActionMessageRecipientInfo,
  AttachmentDetail,
  DeliveredMessages,
  Direction,
  InvalidMessages,
  Message,
  MessageActionItem,
  MessageDirection,
  MessageMetadata,
  MessageReportResult,
  MessageSource,
  MessageUpdateReport,
  RedeliverMessageAction,
  RedeliverMessageResult,
  Search
} from 'types/Messages'
import { RootState } from 'redux/toolkit/store'
import { generateFilterString } from 'lib/searchForm'
import { MetadataResponse } from 'types/general'
import { hasRight, UserRights, UserRole } from 'components/libs/userRights/useUserRights'
import { getEndDate, getStartDate } from 'lib/mstore'
import { reset as resetMessagesTable } from 'redux/features/dataTables/messages/messagesSlice'
import { AsyncThunkConfig } from 'lib/reduxStore'
import { SearchSettings, MessageSearchFilters } from 'lib/services/searchSettings/searchSettingsRepository'
import { INITIAL_STATE, setSearchFilter, setSearchTerm } from 'redux/features/mstore/mstoreSlice'
import { AppTypes } from 'types/AppTypes'

export interface GetMessagePayload {
  headersOnly: number
  showImages: number
  messageId: string
  domainId: string
  userId: string | undefined
}

export interface GetMessageResponse {
  message: Message
  metadata: MetadataResponse
}

export interface GetMessageSourcePayload extends GetMessagePayload {
  parse: number
}

export interface GetAttachmentPayload {
  messageId: string
  attachmentId: string
  domainId: string
  userId: string | undefined
}

export type GetSearchPayload = Search

export interface PostBlockSenderItem {
  emailOrDomain?: string
  messageId: string
  domainId: number
}

export type PostBlockSenderPayload = PostBlockSenderItem[]

export interface PostRedeliverMessageResponse {
  results: RedeliverMessageResult[]
  metadata: MessageMetadata
}

export interface MessageId {
  messageId: Message['mid']
}

export interface PostAllowSenderPayload {
  items: MessageActionItem[]
  atpBypass: boolean
}

export interface PostRedeliverMessagePayload {
  items: MessageActionItem[]
  atpBypass: boolean
  removeDelivered?: boolean
}

export interface RedeliverMessagePayload {
  items: RedeliverMessageAction[]
  remove_delivered?: boolean
}

export interface RedeliverMessageResponse {
  success: boolean
  deleted_message_ids: string[]
  delivered_messages: DeliveredMessages[]
  invalid_messages: InvalidMessages[]
  error?: string
}

export interface DeliverMessagePayload {
  items: ActionMessageInfo[]
  atp_bypass: boolean
  remove_delivered?: boolean
}

export interface DeliverMessageResponse {
  success: boolean
  deleted_message_ids: string[]
  delivered_messages: DeliveredMessages[]
  invalid_messages: InvalidMessages[]
  error?: string
}

export type PostRecategorizePayload = {
  messageIds: string[]
  category: string
  customCategory?: string
}

export interface MessageActionPayload {
  items: MessageActionItem[]
  userId?: string
}

export interface DownloadMessagePayload {
  messageId: string
  domainId: string
  userId: string | undefined
}

// TODO: fix API swagger doc for the GET message endpoint
// download feature uses only the message field from the response
export interface DownloadMessageResponse {
  message: string
}

export interface ComposerAttachment {
  id: string
  fileName: string
  binaryContent: ArrayBuffer | null
}

export type PostNewEmailPayload = {
  originalMessage?: Message
  from: string
  to: string
  cc?: string
  subject: string
  body: string
  attachments: ComposerAttachment[]
}

export interface GetRedeliveryQueueMessagesResponse {
  results: RedeliverMessageResult[]
  metadata: MessageMetadata
}

export interface DownloadAttachmentPayload {
  messageId: string
  attachmentId: string
  domainId: string
  userId: string | undefined
}

// TODO: fix API swagger doc for the GET attachment endpoint
// download feature uses only the listed attachment field from the response
export interface DownloadAttachmentResponse {
  attachment: {
    content: string
    contentType: string
    filename: string
  }
}

export interface WhitelistPayload {
  items: ActionMessageInfo[]
  atp_bypass: boolean
}

export interface WhitelistResponse {
  success: boolean
  delivered_messages: DeliveredMessages[]
  invalid_messages: InvalidMessages[]
  error?: string
}

export interface BlockSenderPayload {
  items: ActionMessageInfo[]
  type: 'domain' | 'email'
}

export interface BlockSenderResponse {
  success: boolean
  blocked_senders: string[]
}

export type RejectMessagesPayload = Array<{ messageId: string; domainId: number }>

export type RejectMessageResponse = Array<{
  messageId: string
  domainId: number
  deleteError: string | null
  rejectError: string | null
}>

export const getMessage = createAsyncThunk<GetMessageResponse, GetMessagePayload, ApiRejectResponse>(
  'MSTORE/getMessage',
  async (payload, { rejectWithValue }) => {
    try {
      const { messageId, domainId, userId } = payload
      const apiEndpoint = userId ? apiRoutes.USER_MESSAGE : apiRoutes.DOMAIN_MESSAGE
      const resp = await restClient(apiEndpoint, {
        params: {
          headers_only: payload.headersOnly,
          show_images: payload.showImages
        },
        urlParams: { messageId, domainId, userId }
      })
      // UTF-8 body fix
      const bufferObj = Buffer.from(resp.data.message.body, 'base64')
      resp.data.message.body = bufferObj.toString('utf8')

      return objectToCamel(resp.data) as any
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getMessageSource = createAsyncThunk<MessageSource, GetMessageSourcePayload, ApiRejectResponse>(
  'MSTORE/getMessageSource',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { messageId, domainId, userId } = payload
      const apiEndpoint = userId ? apiRoutes.USER_MESSAGE : apiRoutes.DOMAIN_MESSAGE
      const resp = await restClient(apiEndpoint, {
        params: {
          headers_only: payload.headersOnly,
          parse: payload.parse
        },
        urlParams: { messageId, domainId, userId }
      })

      if (!resp.data) {
        dispatch(
          setErrorSnackBar({
            message: 'get_message_source_failed'
          })
        )

        return rejectWithValue(String(resp.status))
      }

      return resp.data.message
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message:
            e.code === DOMException.QUOTA_EXCEEDED_ERR ? 'local_storage_quota_exceeded' : 'get_message_source_failed'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAttachment = createAsyncThunk<AttachmentDetail, GetAttachmentPayload, ApiRejectResponse>(
  'MSTORE/getAttachment',
  async (payload, { rejectWithValue }) => {
    try {
      const { attachmentId, messageId, domainId, userId } = payload
      const apiEndpoint = userId ? apiRoutes.USER_ATTACHMENT : apiRoutes.DOMAIN_ATTACHMENT
      const resp = await restClient(apiEndpoint, {
        urlParams: {
          messageId,
          attachmentId,
          domainId,
          userId
        }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getSearch = createAsyncThunk<
  MessageReportResult,
  Search | { keepPagination: boolean } | undefined,
  ApiRejectResponse
>('MSTORE/getSearch', async (payload, { rejectWithValue, getState, dispatch }) => {
  const {
    dataTables: { messages: messagesTable },
    mstore: { searchTerm: query, searchFilter }
  } = getState() as RootState
  try {
    const { keepPagination } = payload || { keepPagination: false }
    if (payload) {
      // eslint-disable-next-line no-param-reassign
      delete payload.keepPagination
      if (Object.keys(payload).length === 0) {
        // eslint-disable-next-line no-param-reassign
        payload = undefined
      }
    }
    if (!keepPagination) {
      dispatch(resetMessagesTable())
    }

    let params: { domain_id: string | undefined; user_id: string | undefined } & Record<string, unknown>
    if (payload) {
      params = { user_id: undefined, domain_id: undefined, ...payload }
    } else {
      const filterString = generateFilterString(searchFilter)
      params = {
        ...query,
        offset: messagesTable.skip,
        search_str: `${query.search_str} ${filterString}`,
        start: !query.dateRange ? query.start : getStartDate(query.dateRange),
        end: !query.dateRange ? query.end : getEndDate()
      }
    }

    const getEndpoint = (domainId: string | undefined, userId: string | undefined) => {
      if (!domainId && userId) {
        throw new Error('mstore_search_with_user_id_only_supported_for_domains')
      }
      if (!domainId && !userId) {
        return apiRoutes.SEARCH_ACCOUNT_MESSAGES
      }
      if (domainId && userId) {
        return apiRoutes.SEARCH_USER_MESSAGES
      }
      return apiRoutes.SEARCH_DOMAIN_MESSAGES
    }

    const { domain_id: domainId, user_id: userId, ...queryParams } = params
    const resp = await restClient(getEndpoint(domainId, userId), {
      params: queryParams,
      urlParams: {
        userId,
        domainId
      }
    })

    return objectToCamel(resp.data) as any
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const applySearchSettings = createAsyncThunk<void, string, AsyncThunkConfig>(
  'MSTORE/applySearchSettings',
  async (payload, { rejectWithValue, dispatch, getState, extra }) => {
    try {
      const {
        mstore: { searchSettings, searchTerm },
        app: { appType }
      } = getState() as RootState

      const selectedSearchSetting = searchSettings.find(searchSetting => searchSetting.id === payload)
      if (!selectedSearchSetting) {
        return
      }
      const { deliveryStatus, reason, action, direction, start, end, search_str, dateRange, selectedDomainId } =
        selectedSearchSetting.messageSearchFilters

      if (!searchSettings) {
        return
      }
      const outbound = direction === Direction.OUTBOUND ? MessageDirection.OUTBOUND : MessageDirection.INBOUND
      dispatch(
        setSearchTerm({
          ...searchTerm,
          start,
          end,
          search_str,
          domain_id: selectedDomainId,
          outbound: appType === AppTypes.enduser ? searchTerm.outbound : outbound,
          dateRange
        })
      )
      dispatch(
        setSearchFilter({
          delivery_status: deliveryStatus,
          reason,
          action
        })
      )
      dispatch(getSearch({ keepPagination: false }))
    } catch (e) {
      throw rejectWithValue(e)
    }
  }
)

export const getAllSearchSettings = createAsyncThunk<SearchSettings[], void, AsyncThunkConfig>(
  'MSTORE/getAllSearchSettings',
  async (payload, { rejectWithValue, dispatch, getState, extra }) => {
    try {
      const currentState = getState() as RootState
      const service = extra.services.searchSettingsService.fromReduxState(currentState)
      return await service.getAll()
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const saveSearchSettings = createAsyncThunk<void, SearchSettings, AsyncThunkConfig>(
  'MSTORE/saveSearchSettings',
  async (payload, { rejectWithValue, dispatch, getState, extra }) => {
    try {
      const currentState = getState() as RootState
      const service = extra.services.searchSettingsService.fromReduxState(currentState)
      await service.add(payload)
      dispatch(getAllSearchSettings())
      return undefined
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const deleteSearchSettings = createAsyncThunk<void, string, AsyncThunkConfig>(
  'MSTORE/deleteSearchSettings',
  async (payload, { rejectWithValue, dispatch, getState, extra }) => {
    try {
      const currentState = getState() as RootState
      const service = extra.services.searchSettingsService.fromReduxState(currentState)
      await service.delete(payload)
      dispatch(getAllSearchSettings())
      return undefined
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const whitelist = createAsyncThunk<WhitelistResponse, WhitelistPayload, ApiRejectResponse>(
  'MSTORE/whitelist',
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const pdDomainId = (getState() as RootState).auth.accessTokenObject?.pdDomainId
      let endpoint = apiRoutes.USER_WHITELIST
      if (pdDomainId) {
        endpoint = apiRoutes.DOMAIN_WHITELIST
      } else if (hasRight(UserRights.ALLOW_LIST_ACCOUNT_LEVEL)) {
        endpoint = apiRoutes.ACCOUNT_WHITELIST
      }

      const resp = await restClient(endpoint, {
        data: payload
      })

      const { delivered_messages, invalid_messages }: WhitelistResponse = resp.data

      if (delivered_messages.length && invalid_messages.length) {
        dispatch(
          setWarningSnackBar({
            message: 'post_allow_sender_partial',
            params: [delivered_messages.length, invalid_messages.length]
          })
        )
      } else if (delivered_messages.length) {
        dispatch(
          setSuccessSnackBar({
            message: 'post_allow_sender_success',
            params: [delivered_messages.length]
          })
        )
      } else if (invalid_messages.length) {
        dispatch(
          setErrorSnackBar({
            message: 'post_allow_sender_invalid',
            params: [invalid_messages.length]
          })
        )
      }

      return resp.data
    } catch (e) {
      let errorMessage = 'post_allow_sender_failed'
      const response_message = e?.data?.detail.exception_class

      if (response_message === 'MessageWhitelistForbiddenByPolicy') {
        errorMessage = 'post_allow_sender_admin_forbidden'
      }

      dispatch(
        setErrorSnackBar({
          message: errorMessage
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const blockSender = createAsyncThunk<BlockSenderResponse, BlockSenderPayload, ApiRejectResponse>(
  'MSTORE/blockSender',
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const pdDomainId = (getState() as RootState).auth.accessTokenObject?.pdDomainId
      let endpoint = apiRoutes.USER_BLOCK_SENDER
      if (pdDomainId) {
        endpoint = apiRoutes.DOMAIN_BLOCK_SENDER
      } else if (hasRight(UserRights.ACCOUNT_LEVEL_BLOCK_SENDER)) {
        endpoint = apiRoutes.ACCOUNT_BLOCK_SENDER
      }

      const resp = await restClient(endpoint, {
        data: payload
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_block_sender_success',
          params: [resp.data.blocked_senders.join(',')]
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_block_sender_failed'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postBlockSender = createAsyncThunk<MessageUpdateReport, PostBlockSenderPayload, ApiRejectResponse>(
  'MSTORE/postBlockSender',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = hasRight(UserRights.ACCOUNT_LEVEL_BLOCK_SENDER)
        ? apiRoutes.BLOCK_SENDER_ACCOUNT
        : apiRoutes.BLOCK_SENDER_USER
      const resp = await restClient(endpoint, {
        data: payload
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_block_sender_success',
          // currently only 1 sender can be blocked at a time, so this works for now
          params: [resp.data.results[0].emailOrDomain]
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_block_sender_failed'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postAllowSender = createAsyncThunk<MessageUpdateReport, PostAllowSenderPayload, ApiRejectResponse>(
  'MSTORE/postAllowSender',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = hasRight(UserRights.ALLOW_LIST_ACCOUNT_LEVEL)
        ? apiRoutes.ALLOW_SENDER_ACCOUNT
        : apiRoutes.ALLOW_SENDER_USER
      const resp = await restClient(endpoint, {
        data: payload
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_allow_sender_success',
          params: [payload.items.length]
        })
      )

      return resp.data
    } catch (e) {
      let errorMessage = 'post_allow_sender_failed'
      const response_message = e?.data?.detail
      if (response_message === 'Action forbidden by admin policy') {
        errorMessage = 'post_allow_sender_admin_forbidden'
      }

      dispatch(
        setErrorSnackBar({
          message: errorMessage
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const redeliverMessage = createAsyncThunk<RedeliverMessageResponse, RedeliverMessagePayload, ApiRejectResponse>(
  'MSTORE/redeliverMessage',
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const pdDomainId = (getState() as RootState).auth.accessTokenObject?.pdDomainId
      let endpoint = apiRoutes.USER_REDELIVER_MESSAGE
      if (pdDomainId) {
        endpoint = apiRoutes.DOMAIN_REDELIVER_MESSAGE
      } else if (hasRight(UserRights.REDELIVER_ACCOUNT_LEVEL)) {
        endpoint = apiRoutes.ACCOUNT_REDELIVER_MESSAGE
      }

      const resp = await restClient(endpoint, {
        data: {
          ...payload,
          remove_delivered: (getState() as RootState).mstore.viewConfig?.type === MlogViewType.OUTBOUND_QUARANTINE
        }
      })

      const { delivered_messages, invalid_messages }: RedeliverMessageResponse = resp.data

      if (delivered_messages.length && invalid_messages.length) {
        dispatch(
          setWarningSnackBar({
            message: 'redeliver_message_partial',
            params: [delivered_messages.length, invalid_messages.length]
          })
        )
      } else if (delivered_messages.length) {
        dispatch(
          setSuccessSnackBar({
            message: 'redeliver_message_success',
            params: [delivered_messages.length]
          })
        )
      } else if (invalid_messages.length) {
        dispatch(
          setErrorSnackBar({
            message: 'redeliver_message_invalid',
            params: [invalid_messages.length]
          })
        )
      }

      return resp.data
    } catch (e) {
      let errorMessage = 'redeliver_message_failed'
      const response_message = e?.data?.detail.exception_class

      if (response_message === 'RedeliverMessageForbiddenByPolicy') {
        errorMessage = 'redeliver_message_admin_forbidden'
      }

      dispatch(
        setErrorSnackBar({
          message: errorMessage
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deliverMessage = createAsyncThunk<DeliverMessageResponse, DeliverMessagePayload, ApiRejectResponse>(
  'MSTORE/deliverMessage',
  async (payload, { rejectWithValue, dispatch, getState }) => {
    try {
      const pdDomainId = (getState() as RootState).auth.accessTokenObject?.pdDomainId
      let endpoint = apiRoutes.USER_DELIVER_MESSAGE
      if (pdDomainId) {
        endpoint = apiRoutes.DOMAIN_DELIVER_MESSAGE
      } else if (hasRight(UserRights.REDELIVER_ACCOUNT_LEVEL)) {
        endpoint = apiRoutes.ACCOUNT_DELIVER_MESSAGE
      }

      const resp = await restClient(endpoint, {
        data: {
          ...payload,
          remove_delivered: (getState() as RootState).mstore.viewConfig?.type === MlogViewType.OUTBOUND_QUARANTINE
        }
      })

      const { delivered_messages, invalid_messages }: RedeliverMessageResponse = resp.data

      const deletedMessageIds = delivered_messages.map(item => item.id)
      dispatch(removeListItems(deletedMessageIds))
      const { selectedMessageMid } = (getState() as RootState).mstore
      if (selectedMessageMid && deletedMessageIds.includes(selectedMessageMid)) {
        dispatch(resetSelectedMessage())
      }

      if (delivered_messages.length && invalid_messages.length) {
        dispatch(
          setWarningSnackBar({
            message: 'deliver_message_partial',
            params: [delivered_messages.length, invalid_messages.length]
          })
        )
      } else if (delivered_messages.length) {
        dispatch(
          setSuccessSnackBar({
            message: 'deliver_message_success',
            params: [delivered_messages.length]
          })
        )
      } else if (invalid_messages.length) {
        dispatch(
          setErrorSnackBar({
            message: 'deliver_message_invalid',
            params: [invalid_messages.length]
          })
        )
      }

      return resp.data
    } catch (e) {
      let errorMessage = 'deliver_message_failed'
      const response_message = e?.data?.detail.exception_class

      if (response_message === 'DeliverMessageForbiddenByPolicy') {
        errorMessage = 'deliver_message_admin_forbidden'
      }

      dispatch(
        setErrorSnackBar({
          message: errorMessage
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postRedeliverMessage = createAsyncThunk<
  PostRedeliverMessageResponse,
  PostRedeliverMessagePayload,
  ApiRejectResponse
>('MSTORE/postRedeliverMessage', async (payload, { rejectWithValue, dispatch, getState }) => {
  try {
    const pdDomainId = (getState() as RootState).auth.accessTokenObject?.pdDomainId
    const endpoint =
      hasRight(UserRights.REDELIVER_ACCOUNT_LEVEL) || !!pdDomainId
        ? apiRoutes.REDELIVER_MESSAGE_ACCOUNT
        : apiRoutes.REDELIVER_MESSAGE_USER
    const resp = await restClient(endpoint, {
      data: {
        ...payload,
        removeDelivered: (getState() as RootState).mstore.viewConfig?.type === MlogViewType.OUTBOUND_QUARANTINE
      }
    })
    const { results = [] }: PostRedeliverMessageResponse = resp.data
    const deletedMessageIds = results.filter(item => item.isDeleted).map(item => item.messageId)
    dispatch(removeListItems(deletedMessageIds))
    const { selectedMessageMid } = (getState() as RootState).mstore
    if (selectedMessageMid && deletedMessageIds.includes(selectedMessageMid)) {
      dispatch(resetSelectedMessage())
    }
    dispatch(
      setSuccessSnackBar({
        message: 'post_redeliver_message_success',
        params: [payload.items.length || 1]
      })
    )

    return resp.data
  } catch (e) {
    let errorMessage = 'post_redeliver_message_failure'
    const response_message = e?.data?.detail
    if (response_message === 'Action forbidden by admin policy') {
      errorMessage = 'post_redeliver_message_admin_forbidden'
    }
    dispatch(
      setErrorSnackBar({
        message: errorMessage
      })
    )

    return rejectWithValue(validateApiError(e))
  }
})

export const getRedeliveryQueueMessages = createAsyncThunk<
  GetRedeliveryQueueMessagesResponse,
  undefined,
  ApiRejectResponse
>('MSTORE/getRedeliveryQueueMessages', async (_, { rejectWithValue, dispatch, getState }) => {
  try {
    const pdDomainId = (getState() as RootState).auth.accessTokenObject?.pdDomainId
    const userRoleType = (getState() as RootState).auth.accessTokenObject?.roleType
    const domainId = (getState() as RootState).auth.accessTokenObject?.domainId

    let resp = null

    if (pdDomainId) {
      // domain level
      resp = await restClient(apiRoutes.REDELIVERY_QUEUE_DOMAIN_MESSAGES, { urlParams: { domainID: domainId } })
    }
    if (userRoleType === UserRole.ACCOUNT_USER) {
      // account level
      resp = await restClient(apiRoutes.REDELIVERY_QUEUE_ACCOUNT_MESSAGES, {})
    } else {
      // user level
      resp = await restClient(apiRoutes.REDELIVERY_QUEUE_USER_MESSAGES, { urlParams: { domainID: domainId } })
    }

    return resp.data
  } catch (e) {
    dispatch(
      setErrorSnackBar({
        message: 'get_redeliver_message_failure'
      })
    )
    return rejectWithValue(validateApiError(e))
  }
})

export const postRecategorize = createAsyncThunk<MessageUpdateReport, PostRecategorizePayload, ApiRejectResponse>(
  'MSTORE/postRecategorize',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const endpoint = hasRight(UserRights.ACCOUNT_LEVEL_REACATEGORIZE)
        ? apiRoutes.RECATEGORIZE_ACCOUNT
        : apiRoutes.RECATEGORIZE_USER
      const resp = await restClient(endpoint, {
        data: { ...payload }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_recategorize_success',
          params: [payload.messageIds.length || 1]
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_recategorize_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deleteMessage = createAsyncThunk<string[], MessageActionPayload, ApiRejectResponse>(
  'MSTORE/deleteMessage',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const domainIds = Array.from(new Set(payload.items.map(item => item.domainId)))
      const uniqueDomainId = domainIds.length === 1 ? domainIds[0] : undefined
      const { userId } = payload
      const getEndpoint = () => {
        if (userId) {
          return apiRoutes.DELETE_MESSAGES_USER
        }
        if (uniqueDomainId) {
          return apiRoutes.DELETE_MESSAGES_DOMAIN
        }
        return apiRoutes.DELETE_MESSAGES_ACCOUNT
      }
      const { data } = await restClient(getEndpoint(), {
        data: objectToSnake(payload),
        urlParams: {
          domainId: uniqueDomainId,
          userId
        }
      })
      const payloadMids = payload.items.map(item => item.messageId)
      const unhandledMids = payloadMids.filter(mid => !data.result.affectedMessageIds.includes(mid))
      if (unhandledMids.length === payloadMids.length) {
        dispatch(
          setErrorSnackBar({
            message: 'delete_message_failure'
          })
        )
        return rejectWithValue(validateApiError('delete_message_failure'))
      }

      if (unhandledMids.length > 0) {
        dispatch(
          setWarningSnackBar({
            message: 'delete_message_partial_success',
            params: [data.result.affectedMessageIds.length, unhandledMids.length]
          })
        )
      } else {
        dispatch(
          setSuccessSnackBar({
            message: 'delete_message_success',
            params: [payloadMids.length]
          })
        )
      }

      return data.result.affectedMessageIds
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'delete_message_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const downloadMessage = createAsyncThunk<DownloadMessageResponse, DownloadMessagePayload, ApiRejectResponse>(
  'MSTORE/downloadMessage',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { messageId, domainId, userId } = payload
      const apiEndpoint = userId ? apiRoutes.USER_MESSAGE : apiRoutes.DOMAIN_MESSAGE
      const response = await restClient(apiEndpoint, {
        params: {
          parse: 0,
          headers_only: 0
        },
        urlParams: { messageId, domainId, userId },
        callbacks: {
          onDownloadProgress: ({ loaded, total }: { loaded: number; total: number }) => {
            dispatch(setDownloadMessageProgress({ loaded, total }))
          }
        }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'download_message_success'
        })
      )

      return response.data.message
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'download_message_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postNewEmail = createAsyncThunk<undefined, PostNewEmailPayload, ApiRejectResponse>(
  'MSTORE/postNewEmail',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData()
      formData.append('from', payload.from)
      formData.append('to', payload.to)
      formData.append('cc', payload.cc || '')
      formData.append('subject', payload.subject)
      formData.append('body', payload.body)

      const fetchAttachments: AxiosPromise[] = []
      payload.attachments.forEach(attachment => {
        if (attachment.binaryContent !== null) {
          const file = new File([attachment.binaryContent], attachment.fileName)
          formData.append('attachments', file, attachment.fileName)
          return
        }
        if (!payload.originalMessage) {
          // This is a new email without an original message reference, can't fetch existing attachments
          return
        }
        fetchAttachments.push(
          restClient(apiRoutes.ATTACHMENT, {
            urlParams: {
              messageId: payload.originalMessage.mid,
              attachmentId: attachment.id
            }
          })
        )
      })
      const fetchedAttachments = await Promise.all(fetchAttachments)
      fetchedAttachments.forEach(({ data: { attachment } }) => {
        if (!attachment) {
          return
        }
        const file = new File([attachment.binaryContent], attachment.fileName)
        formData.append('attachments', file, attachment.fileName)
      })

      const resp = await restClient(apiRoutes.DELIVER_NEW, {
        data: formData as any,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      dispatch(
        setSuccessSnackBar({
          message: 'post_new_email_success'
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_new_email_failure'
        })
      )

      return rejectWithValue(validateApiError(e))
    }
  }
)

export const downloadAttachment = createAsyncThunk<
  DownloadAttachmentResponse,
  DownloadAttachmentPayload,
  ApiRejectResponse
>('MSTORE/downloadAttachment', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { attachmentId, messageId, domainId, userId } = payload
    const apiEndpoint = userId ? apiRoutes.USER_ATTACHMENT : apiRoutes.DOMAIN_ATTACHMENT
    const response = await restClient(apiEndpoint, {
      params: {
        parse: 0,
        headers_only: 0
      },
      urlParams: {
        messageId,
        attachmentId,
        domainId,
        userId
      },
      callbacks: {
        onDownloadProgress: ({ loaded, total }: { loaded: number; total: number }) => {
          dispatch(setDownloadAttachmentProgress({ loaded, total }))
        }
      }
    })

    dispatch(
      setSuccessSnackBar({
        message: 'download_attachment_success'
      })
    )

    return response.data
  } catch (e) {
    dispatch(
      setErrorSnackBar({
        message: 'download_attachment_failure'
      })
    )

    return rejectWithValue(validateApiError(e))
  }
})

export const postRejectMessages = createAsyncThunk<RejectMessageResponse, RejectMessagesPayload, ApiRejectResponse>(
  'MSTORE/postRejectMessages',
  async (payload, { rejectWithValue, dispatch, getState }) => {
    const deletedMessageIds: string[] = []
    try {
      const resp = await restClient(apiRoutes.REJECT_MESSAGES_ACCOUNT, {
        data: payload
      })

      let errorCount = 0
      const {
        data: { results }
      }: { data: { results: RejectMessageResponse } } = resp
      results.forEach(item => {
        if (item.rejectError || item.deleteError) {
          errorCount++
        } else {
          deletedMessageIds.push(item.messageId)
        }
      })

      let setSnackBar: typeof setSuccessSnackBar | typeof setWarningSnackBar | typeof setErrorSnackBar
      let snackbarMessage: string
      let snackbarParams: number[] | undefined

      switch (true) {
        case payload.length === errorCount:
          setSnackBar = setErrorSnackBar
          snackbarMessage = 'post_reject_messages_failed'
          snackbarParams = undefined
          break
        case errorCount > 0:
          setSnackBar = setWarningSnackBar
          snackbarMessage = 'post_reject_messages_partial_success'
          snackbarParams = [payload.length - errorCount, errorCount]
          break
        default:
          setSnackBar = setSuccessSnackBar
          snackbarMessage = 'post_reject_messages_success'
          snackbarParams = [payload.length]
      }

      dispatch(
        setSnackBar({
          message: snackbarMessage,
          params: snackbarParams
        })
      )

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_reject_messages_failed'
        })
      )

      return rejectWithValue(validateApiError(e))
    } finally {
      const { selectedMessageMid } = (getState() as RootState).mstore
      if (selectedMessageMid && deletedMessageIds.includes(selectedMessageMid)) {
        dispatch(resetSelectedMessage())
      }
      dispatch(removeListItems(deletedMessageIds))
    }
  }
)
