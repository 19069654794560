import React, { useMemo } from 'react'
import { RadioGroup, useTokens } from '@barracuda-internal/bds-core-v2'
import { useFormatMessage } from 'lib/localization'
import SetupFlowStepsContentContainer from '../../layout/SetupFlowStepsContentContainer'
import ContentTitleComponent from '../../components/ContentTitleComponent'
import { DeploymentSetupOptions } from '../../types/egdWizardTypes'
import RadioBox from './components/RadioBox'
import { useDeploymentSetupLogic } from './useDeploymentSetupLogic'

const BASE_I18N_KEY = 'ess_wizard.deployment_setup'

const DeploymentSetup: React.FC = () => {
  const tokens = useTokens()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useDeploymentSetupLogic()

  return useMemo(
    () => (
      <SetupFlowStepsContentContainer>
        <ContentTitleComponent title={formatMessage('title')} subtitle={formatMessage('subtitle')} />
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={state.deploymentSetup}
          onChange={eventHandlers.onSelectChange}
          name="radio-buttons-group"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            padding: tokens.aliasesSpaceContainerDefault,
            gap: tokens.aliasesSpaceContainerDefault
          }}
        >
          <RadioBox
            bold
            radioLabel={formatMessage('mx_record')}
            radioValue={DeploymentSetupOptions.mxRecord}
            title={formatMessage('mx_record_title')}
            subtitle={formatMessage('mx_record_subtitle')}
            buttonText={formatMessage('learn_more')}
          />
          <RadioBox
            disabled
            radioLabel={formatMessage('inline')}
            radioValue={DeploymentSetupOptions.inline}
            title={formatMessage('inline_title')}
            subtitle={formatMessage('inline_subtitle')}
            buttonText={formatMessage('learn_more')}
          />
        </RadioGroup>
      </SetupFlowStepsContentContainer>
    ),
    [formatMessage, tokens, state, eventHandlers]
  )
}

export default DeploymentSetup
