export enum SetupFlowSteps {
  introduction = 'introduction',
  microsoftConnected = 'microsoftConnected',
  regionSelection = 'regionSelection',
  verifyDomain = 'verifyDomain',
  deploymentSetup = 'deploymentSetup',
  emailFlow = 'emailFlow'
}

export enum DeploymentSetupOptions {
  inline = 'inline',
  mxRecord = 'mx_record'
}

export enum EmailFlowSteps {
  addNewMxRecords = 'addNewMxRecords',
  removeOldMxRecords = 'removeOldMxRecords'
}

export type EmailFlowVerifiedStates = {
  [key in EmailFlowSteps]: boolean
}

export type EGDSectionsProps = {
  isRequestedToCompleteSetup?: boolean
  setSpecifyEmailServerSectionErrorState?: (results: boolean) => void
  setIsAddMxRecordsSectionHasError?: (results: boolean) => void
  setIsRemoveOldMxRecordsSectionHasError?: (results: boolean) => void
  sectionsVerifiedStates: { [key in EmailFlowSteps]: boolean }
}
