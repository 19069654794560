import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'
import { EmailFlowVerifiedStates, EmailFlowSteps } from 'components/pages/setupFlow//types/egdWizardTypes'

export interface State {
  sectionsIsVerifiedStates: EmailFlowVerifiedStates
}

export interface EventHandlers {
  setSectionVerifiedState: (
    section: EmailFlowSteps,
    localSetter: Dispatch<SetStateAction<boolean>>
  ) => (result: boolean) => void
  setIsAddMxRecordsSectionHasError: Dispatch<SetStateAction<boolean>>
  setIsRemoveOldMxRecordsSectionHasError: Dispatch<SetStateAction<boolean>>
}

export interface TestHelpers {
  [key: string]: Dispatch<SetStateAction<any>>
}

export type EmailFlowLogic = [State, EventHandlers, TestHelpers]

export function getSectionElement(sectionName: EmailFlowSteps): HTMLAreaElement | undefined {
  const sectionElement = document.querySelectorAll(`[data-wizard-section=${sectionName}]`)

  if (sectionElement.length) {
    return sectionElement[0] as HTMLAreaElement
  }

  return undefined
}

export const useEmailFlowLogic = (): EmailFlowLogic => {
  const { mxRecords, oldMxRecords } = useAppSelector(_store => ({
    mxRecords: _store.emailServer.mxRecords,
    oldMxRecords: _store.emailServer.oldMxRecords
  }))
  const [validatedSections, setVelidatedSections] = useState<EmailFlowSteps[]>([])
  const [isAddMxRecordsSectionHasError, setIsAddMxRecordsSectionHasError] = useState<boolean>(false)
  const [isRemoveOldMxRecordsSectionHasError, setIsRemoveOldMxRecordsSectionHasError] = useState<boolean>(false)

  const sectionsErrorStates = useMemo(
    () => ({
      [EmailFlowSteps.addNewMxRecords]: isAddMxRecordsSectionHasError,
      [EmailFlowSteps.removeOldMxRecords]: isRemoveOldMxRecordsSectionHasError
    }),
    [isAddMxRecordsSectionHasError, isRemoveOldMxRecordsSectionHasError]
  )

  const sectionsIsVerifiedStates = useMemo(
    () => ({
      [EmailFlowSteps.addNewMxRecords]:
        !sectionsErrorStates[EmailFlowSteps.addNewMxRecords] &&
        !!mxRecords &&
        !Object.values(mxRecords)?.some(record => !record.verified),
      [EmailFlowSteps.removeOldMxRecords]:
        !sectionsErrorStates[EmailFlowSteps.removeOldMxRecords] &&
        !!oldMxRecords &&
        !oldMxRecords.some(record => !record.verified)
    }),
    [mxRecords, oldMxRecords, sectionsErrorStates]
  )

  const setSectionVerifiedState = useCallback(
    (section: EmailFlowSteps, localSetter: Dispatch<SetStateAction<boolean>>) => (result: boolean) => {
      if (!validatedSections.includes(section)) {
        setVelidatedSections(validatedSections.concat(section))
      }
      localSetter(result)
    },
    [validatedSections]
  )

  return useMemo(
    () => [
      {
        sectionsIsVerifiedStates
      },
      {
        setSectionVerifiedState,
        setIsAddMxRecordsSectionHasError,
        setIsRemoveOldMxRecordsSectionHasError
      },
      {
        setVelidatedSections
      }
    ],
    [
      sectionsIsVerifiedStates,
      setSectionVerifiedState,
      setVelidatedSections,
      setIsAddMxRecordsSectionHasError,
      setIsRemoveOldMxRecordsSectionHasError
    ]
  )
}
